<template>
  <div>
    <v-container fluid class="pa-6 ">
      <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl" @openSuperlead="openSuperlead" @openSuperleadForm="openSuperleadForm"/>
    </v-container>
  </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import DatatableV2 from '@/components/DatatableV2'

export default {
  name: "LeadList",
  components: {
    DatatableV2
  },
  data() {
    return {
      apiUrl:"",
    };
  },
  mounted() {
    // if(this.$store.state.auth.user.is_presta)
    //     this.apiUrl = "/mission/getDemands";
    // else
        this.apiUrl = "/superlead/list";
  },
  computed: {
  },
  methods: {
    openSuperlead(superlead) {
        GenericDataService.getData('superlead/get?id='+superlead.id+'&operation_id='+(superlead.operation_id.id ? superlead.operation_id.id : superlead.operation_id)).then((response)=> {
             this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
        });
    },
    openSuperleadForm(id, superlead){
        this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", superlead);
    }
  },
};
</script>
