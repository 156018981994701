<template>
    <div class="wrapper">
        <v-expand-transition>
            <router-view />
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    name: "Form",
    components: {
    },
    data() {
        return {
        };
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_SUB_MENU', []);
        next();
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    },
};
</script>
